import Image from 'next/image';
import Link from 'next/link';
import { Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface TabBarItemDataProps {
  title: string;
  icon: string;
  href: string;
  hidden?: boolean;
}

export const TabBarDataItems: TabBarItemDataProps[] = [
  {
    title: '美國置產',
    icon: 'abroad',
    href: '/abroad',
  },
  {
    title: '全新建案',
    icon: 'search',
    href: '/building',
  },
  {
    title: '首頁',
    icon: 'home',
    href: '/',
  },
  {
    title: '聯絡我們',
    icon: 'call',
    href: '/contact',
  },
  {
    title: '更多',
    icon: 'more',
    href: '/more',
  },
  {
    title: '全新建案',
    icon: 'building',
    href: '/building',
    hidden: true,
  },
  {
    title: '賣屋',
    icon: 'sell',
    href: '/best-agency',
    hidden: true,
  },
];

interface TabItemProps extends TabBarItemDataProps {
  active: boolean;
}

const TabItem = ({ active, icon, title, href, hidden }: TabItemProps) => {
  if (hidden) return null;
  return (
    <Link passHref href={href}>
      <VStack alignItems={'center'} justify={'flex-end'} flex={1}>
        <Stack w={'25px'} h={'25px'}>
          <Image
            alt={`${icon}-${active ? 'active' : 'inactive'}`}
            color={'black'}
            width={'25px'}
            height={'25px'}
            layout={'responsive'}
            src={`/tabIcons/${icon}-${active ? 'active' : 'inactive'}.svg`}
          />
        </Stack>

        <Stack>
          <Text height={'17px'} color={active ? 'gray.900' : 'gray.700'} className={' -mt-1'} fontSize={'xs'}>
            {title}
          </Text>
        </Stack>
      </VStack>
    </Link>
  );
};
export default function Footer() {
  const router = useRouter();
  const [showFooter, setShowFooter] = useState(false);
  const [activeHref, setActiveHref] = useState<string>(router.asPath);
  useEffect(() => {
    const paths = TabBarDataItems.map((item) => item.href);
    setShowFooter(paths.indexOf(router.pathname) !== -1);
    setActiveHref(router.asPath);
  }, [router]);
  if (!showFooter) return null;
  return (
    <footer className='mt-12'>
      <Stack
        zIndex={999}
        backgroundColor={'white'}
        borderTopWidth={1}
        borderTopColor='gray.300'
        position={'fixed'}
        bottom={0}
        flexDirection={'row'}
        h={'48px'}
        w={'100%'}
      >
        {TabBarDataItems.map((item) => {
          return <TabItem key={item.title} active={activeHref === item.href} {...item} />;
        })}
      </Stack>
    </footer>
  );
}
